main {
    background-color: var(--background-grey);
    display: flex;
    justify-content: center;
}

.main-container {
    height:100%;
    width:100%;
    max-width: 1000px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-h1 {
    text-align: center;
}
.main-h1:after {
    content: '';
    width: 240px;
    margin-top: 10px;
    margin-bottom: 5px;
    /* position: absolute; */
    height: 2px;
    display: block;
    background-color: var(--regular-blue);
}
.main-h2 {
    text-align: center;
}

.main-forms {
    display: flex;

    justify-content: center;
    flex-wrap: wrap ;

}
.form {
    width: 100%;
}
.forms-column {
    width: 300px;
    margin:30px 20px;

}

.form-field-wrapper {
    display: flex;
    flex-direction: column;
    margin-top:10px;
}

.form-field-wrapper input,
.form-field-wrapper select
{
    padding:10px;
    border-radius: 5px;
    border:2px solid var(--regular-blue);
}

.form-field-wrapper select {
    padding:10px 5px;
}

.field-label {
    margin-bottom: 5px;
}
.next-prev-wrapper {

    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    justify-content: center;
    align-items: center;
}
.prev-button {
    border:1px solid #e5e5e5;
    color: #444;
    padding: 20px;
    border-radius: 5px;
    width: 123px;
    margin:10px 14px;
    justify-content: space-evenly;
    display: flex;
    align-items: center;
    background-color: #ededed;
    transition: background-color 0.2s ease-in;
}
.prev-button:hover {
    background-color:#e1e1e1;
    cursor: pointer;
}

.next-button {
    background-color: var(--succes-green);
    color: white;
    padding: 20px;
    margin:10px 14px;
    border-radius: 5px;
    width: 123px;
    justify-content: space-evenly;
    border: 0px;
    display: flex;
    align-items: center;
    transition: background-color 0.2s ease-in;
}
.next-button:hover {
    background-color: #bcd3b1;
    cursor:pointer;
}

.step1 {
   justify-content: center;
}

.language-selector {
    display: flex;
    justify-content: flex-end;
}

@media only screen and (max-width: 1100px) {
    main {
        padding-bottom: 100px;
    }
}