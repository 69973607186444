.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--background-white);
    padding:0 20px;
}

.header .header-logo {
    font-family: 'Arial';
    font-weight: bold;
    font-size:22px;
    font-style: italic;
    height: fit-content;
    text-decoration: none;
    color:#444;
    display: flex;
    align-items: center;

}
.header .header-logo img {
    margin-right: 8px;
}