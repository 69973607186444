.shopping-basket {
    background-color: white;
    border:1px solid lightgray;
    width: 300px;
    height: auto;
    position: fixed;
    bottom:40vh;
    right:20px;
    padding:15px;

    border-radius: 5px;
}

.shopping-basket h2 {
    width: 100%;
    text-align: center;
    margin-top:10px;
    margin-bottom: 10px;
}
.order-line {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.order-line .label {
    text-transform: capitalize;
}

.total-row {
    border-top:1px solid #444;

}

.payment-info {
    background-color: beige;
    padding:10px;
    margin-top:10px;
}

@media only screen and (max-width: 1100px) {
    .shopping-basket {
        width: 90vw;
        bottom:10px;
    }
}