.overview-wrapper {
    margin: 0 auto;
    width: 80%;
    max-width: 300px;

}

.label {
    font-weight: bold;
    font-size: 17px;
}
.value {
    margin-left:20px;
}

.overview-line {

    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}


.terms-and-conditions {
    margin-top:40px;
}