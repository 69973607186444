.input-field {
    border:1px solid #444;
    padding:10px;
    border-radius: 5px;
    position: relative;
}

.description {
    font-size: 12px;
}

.label-checkbox-wrapper {
    font-size: 12px;
    font-weight: bold;
    margin-top: 10px;
}

.input-field input {
    position: absolute;
    top:10px;
    right:10px;
}