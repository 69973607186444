@import url('https://fonts.googleapis.com/css2?family=Play&family=Roboto+Slab&display=swap');

* {
  box-sizing: border-box;
  padding:0px;
  margin:0px;
}

:root {
  /*
  colors
   */
  --regular-blue: #31a6d5;
  --dark-blue: #004a6b;
  --succes-green: #8dab7f;
  --error-red: #c8553d;
  --dark-black: #1f1f1f;
  --background-grey: #f4f4f4;
  --background-white:fff;

  /*
  styling
  */
  --vertical-spacing:20px;

  /*
  fonts
  */

  --h-family: 'Play', sans-serif;
  --content-family: 'Roboto Slab', serif;



}
html {
  height: 100%;
}
body {
  font-family: var(--content-family);
  height:100%;
}
#root {
  height: 100%;
}

h1,h2,h3 {
  font-family: var(--h-family);
}

h3 {
  color: var(--regular-blue);
  font-size: 22px;
}

.contentBox {
  margin-top:var(--vertical-spacing);
  margin-bottom:var(--vertical-spacing);

}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

header {
  flex-basis: 80px;
  flex-shrink: 0;
}

main {
  flex-grow: 1;

}

footer {
  flex-basis: 200px;
  flex-shrink:0;
}